import axios from 'axios'
import qs from 'qs'
import env from './env';
import store from '../src/store/index.js'

// 1.创建axios实例
const instance = axios.create({
    baseURL: env.baseURL,
    timeout: 5000
})

instance.interceptors.request.use(config => {
	config.headers.token = store.state.token
    return config
}, err => {
    console.log(err);

})
instance.interceptors.response.use(res => {
    return res.data
}, err => {
    console.log(err);

})

export function request(config) {
    return instance(config)
}

export function post(url, data) {

    return instance.post(url, qs.stringify(data))

}

const instance1 = axios.create({
    // baseURL: 'http://a.youxuanba.com:8088/',
    baseURL: 'http://120.76.230.110:8088/',
    timeout: 5000
})

instance1.interceptors.request.use(config => {
    return config
}, err => {
    console.log(err);

})
instance1.interceptors.response.use(res => {
    return res.data
}, err => {
    console.log(err);

})

export function maprequest(config) {
	
    return instance1(config)
}

const instance2 = axios.create({
    baseURL: 'http://api.sendebang.com/prod-api/v1/device/',
    // baseURL: 'http://expressway.sendebang.com/',
    timeout: 5000
})

instance2.interceptors.request.use(config => {
	// config.header.contentType = "application/x-www-form-urlencoded";
	// config.headers={}
	// config.headers.contentType = "application/x-www-form-urlencoded";
	// config.headers.Accept = 'application/x-www-form-urlencoded';
	// config.headers.ContentType = 'application/x-www-form-urlencoded';
	// console.log('config',config)
    return config
}, err => {
    console.log(err);

})
instance2.interceptors.response.use(res => {
    return res.data
}, err => {
    console.log(err);

})

export function carrequest(config) {
    return instance2(config)
}


const instance3 = axios.create({
    baseURL: 'http://120.76.230.110:8088/',
    // baseURL: 'http://expressway.sendebang.com/',
    timeout: 5000
})

instance3.interceptors.request.use(config => {
	// config.header.contentType = "application/x-www-form-urlencoded";
	// config.headers={}
	// config.headers.contentType = "application/x-www-form-urlencoded";
	// config.headers.Accept = 'application/x-www-form-urlencoded';
	// config.headers.ContentType = 'application/x-www-form-urlencoded';
	// console.log('config',config)
    return config
}, err => {
    console.log(err);

})
instance3.interceptors.response.use(res => {
    return res.data
}, err => {
    console.log(err);

})

export function hikrequest(config) {
    return instance3(config)
}
