import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "bootstrap/dist/css/bootstrap.css"

import 'element-ui/lib/theme-chalk/index.css';

// 引入element ui
import 'element-ui/lib/theme-chalk/index.css';
import { Message,Dialog} from 'element-ui'

import VideoPlayer from 'vue-video-player';
import 'video.js/dist/video-js.css';
import 'vue-video-player/src/custom-theme.css';
import videojs from 'video.js';
window.videojs = videojs;
require('videojs-contrib-hls/dist/videojs-contrib-hls.js');

Vue. use( VideoPlayer)
Vue.component(Message.name, Message)
Vue.component(Dialog.name, Dialog)
Vue.prototype.$message = Message
// 引入axios
import axios from '../utils/request.js';
Vue.prototype.$axios = axios;
Vue.config.productionTip = false

// import VueAMap from "vue-amap";
// Vue.use(VueAMap);
// VueAMap.initAMapApiLoader({
// 	key: "843930cc05849660eeb95bf3eae8bfa5",
// 	plugin: [
// 		"AMap.Autocomplete",
// 		"AMap.PlaceSearch",
// 		"AMap.Scale",
// 		"AMap.OverView",
// 		"AMap.ToolBar",
// 		"AMap.MapType",
// 		"AMap.PolyEditor",
// 		"AMap.CircleEditor",
// 		'AMap.MarkerClusterer',
// 	],
// 	uiVersion: "1.0.11", // 版本号
// 	// 默认高德 sdk 版本为 1.4.4
// 	v: "1.4.15"
// });
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
