import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		redirect: {
			name: 'login'
		},
		component: () => import('../views/login.vue')
	}, {
		path: '/login',
		name: 'login',
		component: () => import('../views/login.vue')
	},

	// {
	// 	path: '/sanxiang',
	// 	name: 'sanxiang',
	// 	component: () => import('../views/sanxiang.vue')
	// },
	// {
	// 	path: '/milerank',
	// 	name: 'milerank',
	// 	component: () => import('../views/milerank.vue')
	// },
	{
		path: '/map',
		name: 'map',
		component: () => import('../views/map.vue')
	}	
]

const router = new VueRouter({
	routes
})

// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
	if (to.path === '/login') {
		next();
	} else {
		let token = localStorage.getItem('token');
		if (token === 'null' || token === '') {
			next('/login');
		} else {
			next();
		}
	}
});

export default router
